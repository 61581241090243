import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../layouts/full/shared/loadable/Loadable";

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import("../layouts/full/FullLayout")));
const AdminLayout = Loadable(
  lazy(() => import("../layouts/admin/AdminLayout"))
);
const SeketLayout = Loadable(
  lazy(() => import("../layouts/seket/SeketLayout"))
);
const LagLayout = Loadable(lazy(() => import("../layouts/lag/LagLayout")));
const BlankLayout = Loadable(
  lazy(() => import("../layouts/blank/BlankLayout"))
);

/* ****Pages***** */
const Hem = Loadable(lazy(() => import("../views/Frontend/hem/Hem")));

const Info = Loadable(lazy(() => import("../views/Frontend/info/Info")));

const Simhall = Loadable(
  lazy(() => import("../views/Frontend/simhall/Simhall"))
);

const Kontakt = Loadable(
  lazy(() => import("../views/Frontend/kontakt/Kontakt"))
);
const Partners = Loadable(
  lazy(() => import("../views/Frontend/partners/Partners"))
);
const Tabeller = Loadable(
  lazy(() => import("../views/Frontend/tabeller/Tabeller"))
);
const Tv = Loadable(lazy(() => import("../views/Frontend/tv/Tv")));
const Poangliga = Loadable(
  lazy(() => import("../views/Frontend/poangliga/Poangliga"))
);
const Match = Loadable(lazy(() => import("../views/Frontend/match/Match")));
const Disco = Loadable(lazy(() => import("../views/Frontend/disco/Disco")));
const Final = Loadable(
  lazy(() => import("../views/Frontend/finalfredag/Final"))
);

//Auth sidor
const Error = Loadable(lazy(() => import("../views/authentication/Error")));
const KontoVantarGodkannande = Loadable(
  lazy(() => import("../views/authentication/KontoVantarGodkannande"))
);
const KontoEjGodkannande = Loadable(
  lazy(() => import("../views/authentication/KontoEjGodkannande"))
);
const Register = Loadable(
  lazy(() => import("../views/authentication/Register"))
);
const Login = Loadable(lazy(() => import("../views/authentication/Login")));
const Kiosk = Loadable(lazy(() => import("../views/kiosk/Kiosk")));
const Reset = Loadable(
  lazy(() => import("../views/authentication/PasswordReset"))
);
const Account = Loadable(lazy(() => import("../views/authentication/Account")));

//Admin Sidor
const AdminHem = Loadable(lazy(() => import("../views/admin/hem/Hem")));
const AdminAnmalan = Loadable(
  lazy(() => import("../views/admin/anmalan/Anmalan"))
);
const AdminArskurser = Loadable(
  lazy(() => import("../views/admin/arskurser/Arskurser"))
);
const AdminLag = Loadable(lazy(() => import("../views/admin/lag/Lag")));
const AdminAnvandare = Loadable(
  lazy(() => import("../views/admin/users/Users"))
);
const AdminSpelare = Loadable(
  lazy(() => import("../views/admin/spelare/Spelare"))
);
const AdminIndLag = Loadable(
  lazy(() => import("../views/admin/indLag/IndLag"))
);
const AdminIndUser = Loadable(
  lazy(() => import("../views/admin/indUser/IndUser"))
);
const AdminMatcher = Loadable(
  lazy(() => import("../views/admin/matcher/Matcher"))
);
const AdminInstallningar = Loadable(
  lazy(() => import("../views/admin/installningar/installningar"))
);
const AdminSchema = Loadable(
  lazy(() => import("../views/admin/schema/Schema"))
);

//Seket Sidor
const SeketHem = Loadable(lazy(() => import("../views/seket/Hem")));
const SeketRapportering = Loadable(
  lazy(() => import("../views/seket/Rapportering"))
);

//Lag Sidor
const LagHome = Loadable(lazy(() => import("../views/lag/Home")));

const Router = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Hem /> },
      { path: "/info", element: <Info /> },
      { path: "/partners", element: <Partners /> },
      { path: "/kontakt", element: <Kontakt /> },
      { path: "/tabeller", element: <Tabeller /> },
      { path: "/simhall", element: <Simhall /> },
      { path: "/final", element: <Final /> },
      { path: "/disco", element: <Disco /> },
      { path: "/kiosk", element: <Kiosk /> },
      { path: "/match/*", element: <Match /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/",
    element: <BlankLayout />,
    children: [
      { path: "404", element: <Error /> },
      { path: "/integritetspolicy", element: <Hem /> },
      { path: "/anvandarvillkor", element: <Hem /> },
      { path: "/tv", element: <Tv /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/auth",
    element: <BlankLayout />,
    children: [
      {
        path: "/auth/kontovantargodkannande",
        element: <KontoVantarGodkannande />,
      },
      { path: "/auth/kontoejgodkannande", element: <KontoEjGodkannande /> },
      { path: "/auth/anmalan", element: <Register /> },
      { path: "/auth/login", element: <Login /> },
      { path: "/auth/aterstall", element: <Reset /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/admin",
    element: <AdminLayout />,
    children: [
      { path: "/admin", element: <AdminHem /> },
      { path: "/admin/anmalan", element: <AdminAnmalan /> },
      { path: "/admin/arskurser", element: <AdminArskurser /> },
      { path: "/admin/lag", element: <AdminLag /> },
      { path: "/admin/lag/*", element: <AdminIndLag /> },
      { path: "/admin/spelare/*", element: <AdminSpelare /> },
      { path: "/admin/matcher", element: <AdminMatcher /> },
      { path: "/admin/anvandare", element: <AdminAnvandare /> },
      { path: "/admin/anvandare/*", element: <AdminIndUser /> },
      { path: "/admin/account", element: <Account /> },
      { path: "/admin/schema", element: <AdminSchema /> },
      { path: "/admin/poangliga", element: <Poangliga /> },
      { path: "/admin/installningar", element: <AdminInstallningar /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/seket",
    element: <SeketLayout />,
    children: [
      { path: "/seket", element: <SeketHem /> },
      { path: "/seket/*", element: <SeketRapportering /> },
      { path: "/seket/account", element: <Account /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/lag",
    element: <LagLayout />,
    children: [
      { path: "/lag", element: <LagHome /> },
      { path: "/lag/account", element: <Account /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default Router;
